import React from "react"
import { connect } from "react-redux";

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faHeartbeat } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// library.add(faHeartbeat);


class HowItsPossible2 extends React.Component {
    

    render() {
        return (
            <>
                {/* <!-- Start Why Chose Us */}
                {/* ============================================= --> */}
                <div className="about-area default-padding bottom-0" id="how-its-possible">
                    <div className="container">
                        <div className="choseus-items">
                            <div className="row align-center">
                                {/* <div className="col-lg-6 thumb-box left-thumb">
                                    <img src="assets/images/bg-3.png" alt="Thumb" />
                                </div> */}
                                <div className="col-lg-7 thumb-box">
                                    <div className="thumb">
                                        {/* <i><FontAwesomeIcon icon={['fas', 'heartbeat']} /></i> */}
                                        <img src="/assets/images/bg-3.png" alt="Medical Doctors photo" />
                                        <img src="/assets/images/7-min-431x308-opt.png" alt="Ukraine diploma" />
                                    </div>
                                </div>

                                <div className="col-lg-5 info">
                                {/* <div className="col-lg-6 info"> */}
                                    <h3>How it's possible?</h3>
                                    {/* <h2>A Great Place to Work. A Great Place to Receive Care. Leading Medicine.</h2> */}
                                    <p>
                                        Medical Doctors in Ukraine, professionals, finished 6-year education and 2 years of practice, have a salary close to $6000 <strong>per year</strong>.
                                    </p>
                                    <p>Some <strong>undervalued doctors migrate to IT</strong> where they can get
                                    decent salaries from global companies by working remotely.</p>
                                    <p>They do not work in the healthcare domain anymore.</p>
                                    <p>If you are a business in the healthcare domain, developing your own software or hardware, they may join your team <strong>to improve resulting performance</strong>.</p>
                                    <p>There are a lot of good cases and we will prepare an article to highlight this and explain more. Stay tuned.</p>
                                    {/* <ul>
                                        <li>
                                            <div className="info">
                                                <h4>Emergency care</h4>
                                                <div className="items">
                                                    <span><FontAwesomeIcon icon={['fas', 'check-circle']} /> Primary Care</span>
                                                    <span><FontAwesomeIcon icon={['fas', 'check-circle']} /> Medicine</span>
                                                    <span><FontAwesomeIcon icon={['fas', 'check-circle']} /> Orthopedic</span>
                                                    <span><FontAwesomeIcon icon={['fas', 'check-circle']} /> Cardiology</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Why Chose Us --> */}

                </>
            )
        }
}

const mapStateToProps = store => {
    return {
        scrollToComponent: store.scrollToComponent
    }
}

export default connect(mapStateToProps)(HowItsPossible2);