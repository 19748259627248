import React from "react"
import Helmet from "react-helmet";

// import HeaderTop from "../components/header-top.js";
// import Header from "../components/header.js";
// import Banner from "../components/banner.js"

import Layout from "../components/layout.js"
import Wrapper from "../components/wrapper.js"
import ContactModal from "../components/contact-modal.js";
import HeaderSimple from "../components/header-simple";

import BannerSimple from "../components/sections/banner-simple";
import ValueForBoth from "../components/sections/value-for-both.js";
import HowItsPossible2 from "../components/sections/how-its-possible-2.js";
import ITSpecialities from "../components/sections/it-specialities.js";
import BusinessBenefits from "../components/sections/business-benefits.js";
import Standards from "../components/sections/standards.js";
import StepsToStart from "../components/sections/steps-to-start.js";
import Footer from "../components/sections/footer.js"

// import OurProcess from "../components/sections-other/our-process.js"
// import OurServices from "../components/sections-other/our-process.js"
// import FeatureEntry from "../components/feature-entry.js"
// import HealthTips from "../components/health-tips.js"
// import DoctorsArea from "../components/doctors-area.js"

// import GalleryArea from "../components/gallery-area.js"
// import BlogArea from "../components/blog-area.js"

import "../assets/css/bootstrap.min.css";
import "../assets/css/themify-icons.css";
import "../assets/css/flaticon-set.css";
import "../assets/css/magnific-popup.css";
import "../assets/css/owl.carousel.min.css";
import "../assets/css/owl.theme.default.min.css";
import "../assets/css/animate.css";
import "../assets/css/bootsnav.css";
import "../assets/css/responsive.css";

import "../styles/index.scss";

import store from "../state/createStore";
import { Provider } from "react-redux";


/**
 * @todo map medical specialities to it specialities
 * @todo SEO
 * @todo implement blog
 */

const IndexPage = () => (
    <Provider store={store()}>
        <Layout>
                <Helmet>
                    <title>SOMMER - medical consultants for software development businesses</title>
                    <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="SOMMER - medical consultants for software development businesses" />
                </Helmet>

                <Wrapper>
                    <ContactModal formType="business" />
                    {/*<div className="se-pre-con"></div>*/}

                    {/* <HeaderTop></HeaderTop> */}
                    {/* <Header></Header> */}
                    {/* <Banner></Banner> */}
                    <HeaderSimple />
                    <BannerSimple titleStart="Meet the " titleBold="IT Medical Doctors" formRole="business" />

                    <HowItsPossible2 />
                    <ValueForBoth />
                    <BusinessBenefits />
                    
                    
                    <ITSpecialities />
                    <Standards />
                    <StepsToStart />

                    
                    {/* <OurProcess></OurProcess> */}


                    {/* <OurServices></OurServices>
                    <FeatureEntry></FeatureEntry>
                    <HealthTips></HealthTips>
                    <DoctorsArea></DoctorsArea>
                    
                    <GalleryArea></GalleryArea>
                    <BlogArea></BlogArea>*/}
                    
                    <Footer />
                </Wrapper>

                {/* <script src="assets/js/jquery-1.12.4.min.js"></script>
                <script src="assets/js/popper.min.js"></script>
                <script src="assets/js/bootstrap.min.js"></script>
                <script src="assets/js/equal-height.min.js"></script>
                <script src="assets/js/jquery.appear.js"></script>
                <script src="assets/js/jquery.easing.min.js"></script>
                <script src="assets/js/jquery.magnific-popup.min.js"></script>
                <script src="assets/js/modernizr.custom.13711.js"></script>
                <script src="assets/js/owl.carousel.min.js"></script>
                <script src="assets/js/wow.min.js"></script>
                <script src="assets/js/progress-bar.min.js"></script>
                <script src="assets/js/isotope.pkgd.min.js"></script>
                <script src="assets/js/imagesloaded.pkgd.min.js"></script>
                <script src="assets/js/count-to.js"></script>
                <script src="assets/js/YTPlayer.min.js"></script>
                <script src="assets/js/circle-progress.js"></script>
                <script src="assets/js/jquery.nice-select.min.js"></script>
                <script src="assets/js/bootsnav.js"></script>
                <script src="assets/js/main.js"></script> */}

        </Layout>
    </Provider>
)

export default IndexPage