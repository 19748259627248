import React from "react"
import { connect } from "react-redux";

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// library.add(faAngleRight);

// function handleDummy(e) {}

class ITSpecialities extends React.Component {


    showContactForm = (e) => {
        e.preventDefault();
        this.props.dispatch({
            type: 'CONTACT_FORM_ACTION',
            payload: {
                status: true,
                location: "it-specialities",
                context: { type: 'position', position: e.currentTarget.dataset.position }
            },
        })
    }
  
    render() {
        return (
            <>
                {/* Start Our Process
                =============================================*/}
                <div className="process-area default-padding-bottom">
                    {/* Shape*/}
                    <div className="shape">
                        <img src="assets/images/shape/1.png" alt="Shape"/>
                    </div>
                    {/* End Shape*/}
                    <div className="container">
                        
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="site-heading text-center">
                                    <h4>Competency</h4>
                                    <h2>Medical doctors can re-qualify and can be the most effective in: </h2>
                                </div>
                            </div>
                        </div>

                        <div className="process-items text-center">
                            <div className="row justify-content-md-center mb-4">
                                <div className="col col-lg-3 single-item" onClick={this.showContactForm} data-position="qa">
                                    <div className="item">
                                        <i className="flaticon-doctor"></i>
                                        <h5>Quality Assurance</h5>
                                    </div>
                                </div>
                                <div className="col col-lg-3 single-item" onClick={this.showContactForm} data-position="ba">
                                    <div className="item">
                                        <i className="flaticon-doctor"></i>
                                        <h5>Business Analytic</h5>
                                    </div>
                                </div>
                                <div className="col col-lg-3 single-item" onClick={this.showContactForm} data-position="ds">
                                    <div className="item">
                                        <i className="flaticon-doctor"></i>
                                        <h5>Data Scientist</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col col-lg-3 single-item" onClick={this.showContactForm} data-position="pm">
                                    <div className="item">
                                        <i className="flaticon-doctor"></i>
                                        <h5>Project Manager</h5>
                                    </div>
                                </div>
                                <div className="col col-lg-3 single-item" onClick={this.showContactForm} data-position="po">
                                    <div className="item">
                                        <i className="flaticon-doctor"></i>
                                        <h5>Product Owner</h5>
                                    </div>
                                </div>
                                <div className="col col-lg-3 single-item" onClick={this.showContactForm} data-position="sd">
                                    <div className="item">
                                        <i className="flaticon-doctor"></i>
                                        <h5>Software Developer</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Our Process*/}
            </>
        )
    }
}

export default connect(null)(ITSpecialities);