import React from "react"
import CountTo from 'react-count-to';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faDollarSign, faBug, faBolt, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faDollarSign, faBug, faBolt, faClock);

export default () => (
  <>

                {/* Start Fun Factor Area
                =============================================*/}
                <div className="fun-factor-area text-dark default-padding-bottom">
                    {/* Shape*/}
                    {/* <div className="shape">
                        <img src="assets/images/shape/5.png" alt="Shape"/>
                    </div> */}
                    <div className="container">
                        <div className="fun-fact-items text-center">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 item">
                                    <div className="fun-fact">
                                        <i><FontAwesomeIcon icon={['fas', 'clock']} /></i>
                                        
                                        <span className="medium">time to market</span>
                                        <div className="timer"><CountTo to={12} speed={2000} />% less</div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 item">
                                    <div className="fun-fact">
                                        {/* <i className="flaticon-department"></i> */}
                                        <i><FontAwesomeIcon icon={['fas', 'dollar-sign']} /></i>
                                        
                                        <span className="medium">cost per feature</span>
                                        <div className="timer"><CountTo to={5} speed={2000} />% less</div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 item">
                                    <div className="fun-fact">
                                        <i><FontAwesomeIcon icon={['fas', 'bug']} /></i>
                                        <span className="medium">amount of bugs</span>
                                        <div className="timer"><CountTo to={16} speed={2000} />% less</div>
                                        
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 item">
                                    <div className="fun-fact">
                                        <i><FontAwesomeIcon icon={['fas', 'bolt']} /></i>
                                        <span className="medium">efficiency in the result</span>
                                        <div className="timer"><CountTo to={10} speed={2000} />% more </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Fun Factor Area*/}
    </>
)
