import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faLock, faShieldAlt, faBrain, faFileContract} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faAngleRight, faLock, faShieldAlt, faBrain, faFileContract);

function handleDummy(e) {}

export default () => (
  <>
    {/* <!-- Start Services Area */}
    {/* ============================================= --> */}
    <div className="our-services-area icon-only bg-gray default-padding bottom-less">
        {/* <div className="container-full"> */}
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className="site-heading text-center">
                        <h4>Compliance</h4>
                        <h2>We are compatible with</h2>
                    </div>
                </div>
            </div>


            <div className="row">
                {/* <!-- Single Item --> */}
                <div className="col-lg-3 col-md-3 single-item">
                    <div className="item">
                        {/* <i className="flaticon-wheelchair"></i> */}
                        <i><FontAwesomeIcon icon={['fas', 'lock']} /> </i>
                        <h4><a href={handleDummy()}>ISO 27001</a></h4>
                        <p>
                            An international standard on how to manage information security.  It details requirements
                            for establishing, implementing, maintaining and continually improving an information
                            security management system (ISMS) – the aim of which is to help organizations make
                            the information assets they hold more secure.
                        </p>
                        {/* <a href={handleDummy()}>Read More <i><FontAwesomeIcon icon={['fas', 'angle-right']} /></i></a> */}
                    </div>
                </div>
                {/* <!-- End Single Item --> */}
                {/* <!-- Single Item --> */}
                <div className="col-lg-3 col-md-3 single-item">
                    <div className="item">
                        {/* <i className="flaticon-cracked-skin"></i> */}
                        <i><FontAwesomeIcon icon={['fas', 'shield-alt']} /> </i>
                        <h4><a href={handleDummy()}>HIPAA</a></h4>
                        <p>
                            United States federal statute, created primarily to modernize the flow of healthcare
                            information, stipulate how personally identifiable information maintained by the
                            healthcare and healthcare insurance industries should be protected from fraud and
                            theft, and address limitations on healthcare insurance coverage
                        </p>
                        {/* <a href={handleDummy()}>Read More <i><FontAwesomeIcon icon={['fas', 'angle-right']} /></i></a> */}
                    </div>
                </div>
                {/* <!-- End Single Item --> */}
                {/* <!-- Single Item --> */}
                <div className="col-lg-3 col-md-3 single-item">
                    <div className="item">
                        {/* <i className="flaticon-scalpel"></i> */}
                        <i><FontAwesomeIcon icon={['fas', 'brain']} /> </i>
                        <h4><a href={handleDummy()}>CMMI Dev / 3</a></h4>
                        <p>
                        CMMI Services is an integrated set of best practices that improves performance and key
                        capabilities for organizations that provide services, including B2B, B2C, standalone
                        services, and those that are part of a product offering.
                        </p>
                        {/* <a href={handleDummy()}>Read More <i><FontAwesomeIcon icon={['fas', 'angle-right']} /></i></a> */}
                    </div>
                </div>
                {/* <!-- End Single Item --> */}
                {/* <!-- Single Item --> */}
                <div className="col-lg-3 col-md-3 single-item">
                    <div className="item">
                        {/* <i className="flaticon-scalpel"></i> */}
                        <i><FontAwesomeIcon icon={['fas', 'file-contract']} /> </i>
                        <h4><a href={handleDummy()}>NDA protected</a></h4>
                        <p>
                        We are signing a Non-disclosure agreement with our clients and employees, which protects your
                        company and you, by restricting us from sharing confidential material, knowledge, or information.
                        <br />
                        Regarding the word of mouth, we rely on your good will to give us a reference, which we would highly appreciate.
                        </p>
                        {/* <a href={handleDummy()}>Read More <i><FontAwesomeIcon icon={['fas', 'angle-right']} /></i></a> */}
                    </div>
                </div>
                {/* <!-- End Single Item --> */}
            </div>
        </div>
    </div>
    {/* <!-- End Services Area --> */}

  </>
)
