import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


library.add(faAngleRight, faCheckCircle);

function handleDummy(e) {}

export default () => (
  <>

    {/* <!-- Start Our Services */}
    {/* ============================================= --> */}
    <div className="services-area default-padding pt-5 bottom-0">
    {/* <div className="services-area bg-gray default-padding-bottom bottom-less"> */}
        <div className="container">
            <div className="services-items">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="site-heading text-center">
                            <h4>Value</h4>
                            <h2>For both</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* <!-- Single Item --> */}
                    <div className="services-single col-lg-6">
                        <div className="item">
                            <div className="info">
                                <div className="top">
                                    <i className="flaticon-doctor"></i>
                                    <strong>01</strong>
                                </div>
                                <h4>
                                    <a href={handleDummy()}>Businesses</a>
                                </h4>
                                <p>
                                    Benefits achieved when employing software and hardware development specialist with medical degree are following:
                                </p>
                                <div className="row">
                                    <div className="col-lg-5 mb-4 mr-4">
                                        <i><FontAwesomeIcon icon={['fas', 'check-circle']} /></i> Less bugs related to business logic
                                    </div>
                                    <div className="col-lg-5 mb-4 mr-4">
                                        <i><FontAwesomeIcon icon={['fas', 'check-circle']} /></i> More efficient communication
                                    </div>
                                    <div className="col-lg-5 mb-4 mr-4">
                                        <i><FontAwesomeIcon icon={['fas', 'check-circle']} /></i> Less rates comparing to local medical consultants
                                    </div>
                                    <div className="col-lg-5 mb-4 mr-4">
                                        <i><FontAwesomeIcon icon={['fas', 'check-circle']} /></i> Less time to market
                                    </div>
                                </div>
                                {/* <a className="btn btn-md circle btn-gradient" href={handleDummy()}>Get Appointment <i><FontAwesomeIcon icon={['fas', 'angle-right']} /></i></a> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Item --> */}
                    {/* <!-- Single Item --> */}
                    <div className="services-single col-lg-6">
                        <div className="item">
                            <div className="info">
                                <div className="top">
                                    <i className="flaticon-doctor"></i>
                                    <strong>02</strong>
                                </div>
                                <h4>
                                    <a href={handleDummy()}>Doctors</a>
                                </h4>
                                <p>
                                    Medical doctors employed as software development specialist can achieve:
                                </p>
                                <div className="row">
                                    <div className="col-lg-5 mb-4 mr-4">
                                        <i><FontAwesomeIcon icon={['fas', 'check-circle']} /></i> Express themselves in native knowledge area
                                    </div>
                                    <div className="col-lg-5 mb-4 mr-4">
                                        <i><FontAwesomeIcon icon={['fas', 'check-circle']} /></i> Stay and grow among medical personnel
                                    </div>
                                    <div className="col-lg-5 mb-4 mr-4">
                                        <i><FontAwesomeIcon icon={['fas', 'check-circle']} /></i> Higher salary comparing to other employers
                                    </div>
                                    <div className="col-lg-5 mb-4 mr-4">
                                        <i><FontAwesomeIcon icon={['fas', 'check-circle']} /></i> More satisfaction
                                    </div>
                                </div>
                                {/* <a className="btn btn-md circle btn-gradient" href={handleDummy()}>Get Appointment <i><FontAwesomeIcon icon={['fas', 'angle-right']} /></i></a> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Item --> */}
                </div>
            </div>
        </div>
    </div>
    {/* <!-- End Our Services --> */}





    </>
)
