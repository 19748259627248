import React from "react";
import ContactForm from "./../contact-form";
import { connect } from "react-redux";

class BannerSimple extends React.Component {

    scrollToNextItem = (e) => {
        document.getElementById("how-its-possible").scrollIntoView();
    }

    showContactForm = (e) => {
        e.preventDefault();
        
        this.props.dispatch({
            type: 'CONTACT_FORM_ACTION',
            payload: {
                status: true,
                location: e.currentTarget.dataset.location
            },
        })
    }

    render() {

        return (
        <>

            {/* <!-- Start Banner  */}
            {/* ============================================= --> */}
            <div className="banner-area tab-auto-height pt-50p text-default bg-cover shadow dark text-light" style={{backgroundImage: 'url(/assets/images/background-fullhd-medium.jpg'}}>
                <div className="box-table">
                    <div className="box-cell">
                        <div className="container">
                            <div className="content">
                                <div className="row align-center">
                                    <div className="col-lg-7 col-md-12">
                                        <h2>{this.props.titleStart}<br/><strong>{this.props.titleBold}</strong></h2>
                                        <p>
                                        We bond western business and local IT experts with medical degrees together for the good of both.
                                        </p>
                                        <button data-animation="animated fadeInUp" className="btn btn-md btn-light mb-3 border" data-target="how-its-possible-2" onClick={this.scrollToNextItem} >Discover More</button>
                                        <button data-animation="animated fadeInUp" className="btn btn-md btn-gradient border d-md-none" onClick={this.showContactForm} data-location="header">Request a conversation</button>
                                    </div>
                                    {/* <!-- Start Appoinment Form --> */}
                                    <div className="col-lg-5 col-md-12 appoinment">
                                        <div className="appoinment-box d-none d-md-block text-center">
                                            <div className="heading">
                                                <h4>Request a conversation</h4>
                                            </div>

                                            <ContactForm formClickLocation="banner" formRole={this.props.formRole}></ContactForm>
                                        </div>
                                    </div>
                                    {/* <!-- End Appoinment Form --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Banner --> */}

        </>)
    }
}

export default connect(null)(BannerSimple);