import React from "react"
import { connect } from "react-redux";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faAngleRight);





class StepsToStart extends React.Component {
    showContactForm = (e) => {
        e.preventDefault();
        
        this.props.dispatch({
            type: 'CONTACT_FORM_ACTION',
            payload: {
                status: true,
                location: "steps-to-start",
                context: { type: 'step', step: e.currentTarget.dataset.step }
            },
        })
    }

    render() {
        return (
            <>
                {/* Start Our Process
                =============================================*/}
                <div className="process-area default-padding">
                    {/* Shape*/}
                    {/* <div className="shape">
                        <img src="assets/images/shape/1.png" alt="Shape"/>
                    </div> */}
                    {/* End Shape*/}
                    <div className="container">
                        <div className="process-items">
                            <div className="row">
                                <div className="col-lg-5 heading">
                                    <h2>How to augment your team?</h2>
                                    <p>
                                        You need to make sure you are picking the right person
                                    </p>
                                    <button className="btn btn-md btn-gradient" onClick={this.showContactForm} data-step={null}>Clarify details <i><FontAwesomeIcon icon={['fas', 'angle-right']} /></i></button>
                                </div>
                                <div className="col-lg-7">
                                    <div className="process-items text-center">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 single-item" onClick={this.showContactForm} data-step="inspect">
                                                <div className="item">
                                                    <i className="flaticon-calendar-1"></i>
                                                    <h5>Request and<br />inspect CVs</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 single-item" onClick={this.showContactForm} data-step="interview">
                                                <div className="item">
                                                    <i className="flaticon-doctor"></i> 
                                                    <h5>Interview<br />candidates</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 single-item" onClick={this.showContactForm} data-step="board">
                                                <div className="item">
                                                    <i className="flaticon-heartbeat"></i>
                                                    <h5>Board<br />medical expert</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Our Process*/}
            </>
        )
    }
}

export default connect(null)(StepsToStart);